import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import { FaHome, FaMailBulk } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ItemGallery from "../components/ItemGallery"
import PreviewCompatibleImage from "../components/previewCompatibleImage"

const AboutUsWrapper = styled.article`
  .article-container {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
  .gallery {
    margin: 4rem 0;
    display: flex;
    justify-content: space-between;
  }
  .content {
    text-align: justify;
    text-justify: inter-word;
  }
  .content ::after {
    content: "";
    width: 40px;
    height: 2px;
    background-color: rgba(238, 120, 121, 0.6);
    display: block;
    margin: 1rem 0;
  }
`

const NavWrapper = styled.nav`
  bottom: -25px;
  z-index: 1;
  position: absolute;
  display: flex;

  .to-item-link,
  .to-group-link {
    display: flex;
    align-items: center;
    min-width: 220px;
  }
  .to-group-link {
    background-color: white;
    margin-right: 1rem;
  }

  @media (max-width: 514px) {
    flex-direction: column;
    bottom: -50px;
    .to-item-link,
    .to-group-link {
      padding: 0.6rem 0.6rem;
      min-width: 100%;
    }
    .to-group-link {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`

export const AboutPageTemplate = ({ title, image, images, content }) => (
  <section>
    <PreviewCompatibleImage
      style={{ width: "100vw", maxHeight: "60vh" }}
      imageInfo={image}
    />
    <div
      style={{
        position: "relative",
        margin: "0 auto",
        padding: "0 1rem",
        maxWidth: "1240px",
      }}
    >
      <NavWrapper className="back-btn">
        <Link className="to-group-link" to="/">
          <FaHome style={{ color: "#ee7879" }} />
          <span style={{ marginLeft: "1rem" }}>Strona Główna</span>
        </Link>
        <Link className="to-item-link" to="/kontakt/">
          <FaMailBulk />
          <span style={{ marginLeft: "1rem" }}>Skontaktuj się z nami</span>
        </Link>
      </NavWrapper>
    </div>
    <div className="container">
      <AboutUsWrapper>
        <div className="article-container">
          <header>
            <h1>{title}</h1>
          </header>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="gallery">
            <ItemGallery gallery={images} />
          </div>
        </div>
      </AboutUsWrapper>
    </div>
  </section>
)

const AboutPage = ({ data }) => {
  const { frontmatter: about } = data.markdownRemark
  const { seo } = about
  return (
    <Layout>
      <SEO title={seo.browserTitle} description={seo.description} />
      <AboutPageTemplate
        title={about.title}
        image={about.aboutImage}
        images={about.images}
        content={data.markdownRemark.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const pageQuery = graphql`
  query AboutPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        title
        aboutImage {
          image {
            childImageSharp {
              fluid(
                quality: 100
                traceSVG: {
                  color: "#ee7879"
                  turnPolicy: TURNPOLICY_MINORITY
                  blackOnWhite: false
                }
                maxWidth: 1680
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
        images {
          image {
            id
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
        }
        seo {
          browserTitle
          description
        }
      }
    }
  }
`

export default AboutPage
