import React, { Component } from "react"
import styled from "styled-components"
import PreviewCompatibleImage from "./previewCompatibleImage"
import { FaSearchPlus } from "react-icons/fa"
import Overlay from "./overlay"

const ItemGalleryWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: auto;
  figure {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: 0;
    overflow: hidden;
    position: relative;
    cursor: zoom-in;
    border-radius: 5px;

    .image-overlay {
      background: rgba(238, 120, 121, 0.5);
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      transform: translateY(120%);
      font-size: 2rem;
      color: white;
    }
    :hover .image-overlay {
      transform: translateY(0);
    }
  }
`

class ItemGallery extends Component {
  state = {
    src: null,
    overlayOpen: 0,
  }
  handleImageClick = fluid => {
    this.setState({
      src: fluid,
      overlayOpen: 1,
    })
  }

  backdropClickHandler = () => {
    this.setState({ overlayOpen: 0 })
  }
  render() {
    const { gallery } = this.props
    return (
      <>
        <ItemGalleryWrapper>
          {gallery
            ? gallery.map(item => (
                <figure
                  key={item.image.id}
                  onClick={this.handleImageClick.bind(
                    this,
                    item.image.childImageSharp.fluid
                  )}
                >
                  <PreviewCompatibleImage
                    imageInfo={item}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                  />
                  <div className="image-overlay">
                    <FaSearchPlus />
                  </div>
                </figure>
              ))
            : null}
        </ItemGalleryWrapper>
        <Overlay
          show={this.state.overlayOpen}
          click={this.backdropClickHandler}
          src={this.state.src}
        />
      </>
    )
  }
}

export default ItemGallery
